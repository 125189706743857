import { AdminGroup } from "./admin-group";
import { ExpertPartner } from "./expert-partner";

export interface Company {
    id: string;
    name: string;
    environmentCount: number;
    adminGroupId?: string;
    adminGroup?: AdminGroup;
    expertPartnerId?: string;
    expertPartner?: ExpertPartner;
    internalName: string;
    multipleEnvironmentNamesEnabled: boolean;
    cloudProviderRegionPairs: CloudProviderRegionPair[];
    canReadSpareParts: boolean;
    canReadAlgorithms: boolean;
    canReadTaks: boolean;
    canReadWearMetrics: boolean;
    canEditNoteDetails: boolean;
    canEnableDigitalServices: boolean;
    canCompareConfigurations: boolean;
}

export enum CloudProviderRegionPair {
    AWS_EU = 'AWS_EU',
    AWS_APAC = 'AWS_APAC',
    AZURE_EU = 'AZURE_EU'
}