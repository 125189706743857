import { SelectOption } from "./select-option";

export interface UiComponent {
    id: string;
    name: string;
    description: string;
    type: string;
    typeId: number;
    category: string;
    version: number;
    dependencies: UiComponentDependency[];
    builtIn: boolean;
    releaseNotes: string;
    versions: UiComponentVersion[];
    imageUrl: string;
    referenceUrl: string;
    custom: boolean;
    versionDate: number;
    released: boolean;
    featureFlag: string;
    // custom components
    componentId: string;
    ownerName: string;
    contexts: string;
    tagName: string;
    className: string;
    updatePolicy: string;
    attributes: Attribute[];
    subElements: any[];
    native: boolean;
    icon: boolean;
    iconUrl: string;
    image: boolean;
    screenshot: boolean;
    screenshotUrl: string;
    attributeSections: AttributeSection[];
    templates: ComponentTemplate[];
    variants: ComponentFilter[];
    labels: boolean;
    labelsUrl: string;
    tenants: string[];
    showInComponentList: boolean;
    documented: boolean;
    documentationUrl: string;
    deprecated: boolean;
    deprecation: string;
    draft: boolean;
    metricContext: any;
    propertyContext: any;
    editor: boolean;
}

export interface UiComponentDependency {
    id: string;
    version: string;
}

export interface UiComponentVersion {
    version: string;
    date: number;
}

export interface Attribute {
    name: string;
    label: string;
    description: string;
    section: string;
    type: AttributeType;
    defaultValue: any;
    mandatory: boolean;
    visibilityCondition: VisibilityCondition[];
    deprecated: boolean;
    deprecation: string;
    values: SelectOption[];
    context: AttributeContext;
    propertyContextDynamicName: string;
    metricContext: AttributeMetricContext;
    outputType: AttributeFilterOutputType;
    inputType: AttributeFilterInputType[];
    multiple: boolean;
    aggregationFunctions: AttributeAggregationFunction[];
    timeframes: AttributeTimeframe[];
    tenants: string[];
}

export enum AttributeType {
    STRING = 'STRING',
    INTEGER = 'INTEGER',
    FLOAT = 'FLOAT',
    BOOLEAN = 'BOOLEAN',
    JSON = 'JSON',
    ENUM = 'ENUM',
    ENUM_ARRAY = 'ENUM_ARRAY',
    PROPERTY = 'PROPERTY',
    PROPERTY_ARRAY = 'PROPERTY_ARRAY',
    METRIC = 'METRIC',
    STATISTIC = 'STATISTIC',
    FILTER = 'FILTER',
    COMMAND = 'COMMAND',
    CONFIGURATION_PARAMETER = 'CONFIGURATION_PARAMETER',
    QUERY = 'QUERY',
    GROUP_BY = 'GROUP_BY',
    AGGREGATION = 'AGGREGATION',
    CSS = 'CSS',
    COLUMN_CSS = 'COLUMN_CSS',
    COLOR = 'COLOR',
    SORT_PROPERTIES = 'SORT_PROPERTIES'
}

export enum AttributeContext {
    THING = 'THING',
    LOCATION = 'LOCATION',
    CUSTOMER = 'CUSTOMER',
    PARTNER = 'PARTNER',
    USER = 'USER',
    DYNAMIC = 'DYNAMIC'
}

export enum AttributeMetricContext {
    THING = 'THING',
    LOCATION = 'LOCATION'
}

export enum AttributeFilterOutputType {
    COLOR = 'COLOR',
    FLOAT = 'FLOAT',
    HTML = 'HTML',
    INTEGER = 'INTEGER',
    MAP_FILTER = 'MAP_FILTER',
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    JSON = 'JSON',
    FILE = 'FILE'
}

export enum AttributeFilterInputType {
    COLOR = 'COLOR',
    FLOAT = 'FLOAT',
    HTML = 'HTML',
    INTEGER = 'INTEGER',
    MAP_FILTER = 'MAP_FILTER',
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    JSON = 'JSON',
    FILE = 'FILE',
    COMPOSITE_PART = 'COMPOSITE_PART'
}

export enum AttributeAggregationFunction {
    MIN = 'MIN',
    MAX = 'MAX',
    AVG = 'AVG',
    DELTA = 'DELTA'
}

export enum AttributeTimeframe {
    MINUTES_5 = 'MINUTES_5',
    MINUTES_10 = 'MINUTES_10',
    MINUTES_30 = 'MINUTES_30',
    HOURS_1 = 'HOURS_1',
    HOURS_6 = 'HOURS_6',
    HOURS_12 = 'HOURS_12',
    DAYS_1 = 'DAYS_1',
    MONTHS_1 = 'MONTHS_1',
    YEARS_1 = 'YEARS_1'
}

export interface AttributeSection {
    name: string;
    collapsed: boolean;
    visibilityCondition: VisibilityCondition[];
    tenants: string[];
}

export interface ComponentTemplate {
    name: string;
    label: string;
    group: string;
    description: string;
    context: string;
    templateUrl: string;
    template: string;
    screenshotUrl: string;
    screenshot: string;
    personas: string;
    usage: ComponentTemplateUsage;
    requiredData: string;
}

export enum ComponentTemplateUsage {
    PAGE = 'PAGE',
    THING_DETAILS = 'THING_DETAILS'
}

export interface ComponentFilter {
    name: string;
    label: string;
    description: string;
    category: string;
    inputTypes: ComponentFilterInputType[];
    outputType: ComponentFilterOutputType;
    unitAware: boolean;
    image: boolean;
    imageUrl: string;
    sampleValue: any;
    parameters: ComponentFilterParameter[];
    tenants: string[];
    deprecated: boolean;
    deprecation: string;
}


export enum ComponentFilterInputType {
    STRING = 'STRING',
    BOOLEAN = 'BOOLEAN',
    INTEGER = 'INTEGER',
    HTML = 'HTML',
    COMPOSITE_PART = 'COMPOSITE_PART'
}

export enum ComponentFilterOutputType {
    STRING = 'STRING',
    BOOLEAN = 'BOOLEAN',
    INTEGER = 'INTEGER',
    HTML = 'HTML',
    MAP_FILTER = 'MAP_FILTER'
}

export interface ComponentFilterParameter {
    name: string;
    label: string;
    description: string;
    type: ComponentFilterParameterType;
    defaultValue: any;
}

export enum ComponentFilterParameterType {
    STRING = 'STRING',
    BOOLEAN = 'BOOLEAN',
    INTEGER = 'INTEGER'
}

export interface ComponentLabel {
    text: string;
    translation: string;
    language: string;
}

export interface VisibilityCondition {
    property: string;
    predicate: string;
    value: any;
}