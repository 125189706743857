import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ComplexConfirmDialog, ComplexConfirmDialogConfig } from '../shared/components/dialogs/complex-confirm-dialog.component';
import { ConfirmDialog } from '../shared/components/dialogs/confirm-dialog.component';
import { ResourceConflictDialog } from '../shared/components/dialogs/resource-conflict-dialog.component';

@Injectable({ providedIn: 'root' })
export class DialogService {

    constructor(private dialog: MatDialog) { }

    open(componentType: ComponentType<any>, data?: any, width: string = '520px', maxHeight?: string, autofocusDisabled?: boolean, disableClose: boolean = true): Promise<any> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = autofocusDisabled ? false : 'input'; // autofocus on the first input element
        dialogConfig.disableClose = disableClose;
        dialogConfig.data = data;
        dialogConfig.width = width;
        dialogConfig.maxHeight = maxHeight;
        return firstValueFrom(this.dialog.open(componentType, dialogConfig).afterClosed());
    }

    openLarge(componentType: ComponentType<any>, data?: any): Promise<any> {
        return this.open(componentType, data, '680px');
    }

    openExtraLarge(componentType: ComponentType<any>, data?: any, maxHeight: string = '700px'): Promise<any> {
        return this.open(componentType, data, '800px', maxHeight);
    }

    openExtraExtraLarge(componentType: ComponentType<any>, data?: any, maxHeight: string = '700px'): Promise<any> {
        return this.open(componentType, data, '1200px', maxHeight);
    }

    openConfirm(title: string, message: string): Promise<boolean> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.width = '520px';
        dialogConfig.data = {
            title: title,
            message: message
        };
        return firstValueFrom(this.dialog.open(ConfirmDialog, dialogConfig).afterClosed());
    }

    openComplexConfirm(config: ComplexConfirmDialogConfig): Promise<boolean> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.width = '520px';
        dialogConfig.data = { config: config };
        return firstValueFrom(this.dialog.open(ComplexConfirmDialog, dialogConfig).afterClosed());
    }

    openRestoreWarningDialog(): Promise<boolean> {
        return this.openComplexConfirm({
            title: 'Are you sure to restore to original?',
            subtitle: 'Restore to original',
            icon: '360',
            message: 'By restoring this object to its original state all current data will be lost.',
            closeButton: 'Cancel',
            confirmButton: 'Restore'
        })
    }

    openConflict(fileName: string, fileContent: string): Promise<boolean> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.width = '520px';
        dialogConfig.data = { fileName: fileName, fileContent: fileContent };
        return firstValueFrom(this.dialog.open(ResourceConflictDialog, dialogConfig).afterClosed());
    }

}